import React from "react";
import clsx from "clsx";
import { ActionButton, useDeviceTypes } from "halifax";

import styles from "./styles.module.scss";

interface SearchButtonProps {
  handleOnClick: () => void;
}
export const SearchButton: React.FC<SearchButtonProps> = ({
  handleOnClick,
}) => {
  const { matchesMobile } = useDeviceTypes();

  return (
    <ActionButton
      data-test-id="search-button"
      className={clsx(styles["flight-search-button"], {
        [styles["mobile"]]: matchesMobile,
      })}
      message="Search flights"
      onClick={handleOnClick}
    />
  );
};
