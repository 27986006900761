import { connect } from "react-redux";
import { ITripTerminus } from "redmond";
import { B2BSearchAutocomplete, IB2BSearchAutocompleteProps } from "halifax";

export const mapOriginDispatchToProps = {
  fetchValueCategories: () => ({ type: "NOTHING" }),
};

export const mapOriginStateToProps = () => {
  let loading = false;

  return {
    valueCategories: [],
    loading,
  };
};

export const ConnectedOriginAutocomplete = connect(
  mapOriginStateToProps,
  mapOriginDispatchToProps
)(
  B2BSearchAutocomplete as (
    props: IB2BSearchAutocompleteProps<ITripTerminus | null>
  ) => JSX.Element
);

export const mapDestinationDispatchToProps = {
  fetchValueCategories: () => ({ type: "NOTHING" }),
};

export const mapDestinationStateToProps = () => {
  let loading = false;

  return {
    valueCategories: [],
    loading,
  };
};

export const ConnectedDestinationAutocomplete = connect(
  mapDestinationStateToProps,
  mapDestinationDispatchToProps
)(
  B2BSearchAutocomplete as (
    props: IB2BSearchAutocompleteProps<ITripTerminus | null>
  ) => JSX.Element
);
