import React, { type PropsWithChildren } from "react";
import {
  DesktopPopupModal,
  Icon,
  IconName,
  MobilePopoverCard,
  useDeviceTypes,
} from "halifax";
import { IconButton } from "@material-ui/core";

interface DatePickerModalWrapperProps {
  open: boolean;
  onClose: () => void;
  modalClassName?: string;
  contentClassName?: string;
  headerElement?: string | JSX.Element;
}

export const ModalWrapper: React.FC<
  PropsWithChildren<DatePickerModalWrapperProps>
> = ({
  open,
  onClose,
  modalClassName,
  contentClassName,
  headerElement,
  children,
}) => {
  const { matchesMobile } = useDeviceTypes();

  if (matchesMobile) {
    return (
      <MobilePopoverCard
        topRightButton={
          <IconButton onClick={onClose}>
            <Icon name={IconName.BlueX} />
          </IconButton>
        }
        open={open}
        onClose={onClose}
        className={modalClassName}
        contentClassName={contentClassName}
        fullScreen={true}
        headerElement={headerElement}
      >
        {children}
      </MobilePopoverCard>
    );
  }

  return (
    <DesktopPopupModal
      open={open}
      onClose={onClose}
      className={modalClassName}
      contentClassName={contentClassName}
      invisibleBackdrop={false}
      headerElement={headerElement}
    >
      {children}
    </DesktopPopupModal>
  );
};
