import React from "react";
import { Box, Typography } from "@material-ui/core";
import { AirlineDisplay } from "./AirlineDisplay";
import type { Airline } from "../../../../features/change-for-any-reason/types";

import styles from "./styles.module.scss";

type MobileFlightItineraryTileProps = {
  formattedDate: string;
  originName: string;
  originCode: string;
  departureTime: string;
  destinationName: string;
  destinationCode: string;
  arrivalTime: string;
  flightDuration: string;
  flightStops: string;
  airlines: Airline[];
};

export const MobileFlightItineraryTile: React.FC<
  MobileFlightItineraryTileProps
> = ({
  formattedDate,
  originName,
  originCode,
  departureTime,
  destinationName,
  destinationCode,
  arrivalTime,
  flightStops,
  flightDuration,
  airlines,
}) => {
  return (
    <Box className={styles["mobile-flight-itinerary-tile-root"]}>
      <Typography
        variant="subtitle2"
        component="p"
        className={styles["mobile-flight-itinerary-tile-title"]}
      >
        {`${originName} (${originCode}) to ${destinationName} (${destinationCode}) on `}
        <strong>{formattedDate}</strong>
      </Typography>
      <Box className={styles["mobile-flight-itinerary-tile-details"]}>
        <Box>
          <Typography variant="body1">{`${departureTime} \u2013 ${arrivalTime}`}</Typography>
          <Box>
            {airlines.map(({ code, name }) => (
              <AirlineDisplay key={code} name={name} code={code} />
            ))}
          </Box>
        </Box>
        <Box>
          <Typography variant="body1">{flightDuration}</Typography>
          <Typography variant="body2">{flightStops}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
