// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8vSYzHKPLihqFJA02kHZpA\\=\\= button{border-radius:4px}._8vSYzHKPLihqFJA02kHZpA\\=\\= .MuiTypography-button{font-size:16px}._8vSYzHKPLihqFJA02kHZpA\\=\\=.mqtfIhqbiz2wrmjwNc\\+Gqg\\=\\= button{width:100%}._8vSYzHKPLihqFJA02kHZpA\\=\\=.mqtfIhqbiz2wrmjwNc\\+Gqg\\=\\= .MuiTypography-button{font-size:20px}", "",{"version":3,"sources":["webpack://./../../cap1-modules/trips-module/src/pages/ChangeForAnyReason/components/SearchButton/styles.module.scss"],"names":[],"mappings":"AACE,oCACE,iBAAA,CAEF,mDACE,cAAA,CAIA,gEACE,UAAA,CAEF,+EACE,cAAA","sourcesContent":[".flight-search-button {\n  button {\n    border-radius: 4px;\n  }\n  :global(.MuiTypography-button) {\n    font-size: 16px;\n  }\n\n  &.mobile {\n    button {\n      width: 100%;\n    }\n    :global(.MuiTypography-button) {\n      font-size: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flight-search-button": "_8vSYzHKPLihqFJA02kHZpA==",
	"mobile": "mqtfIhqbiz2wrmjwNc+Gqg=="
};
export default ___CSS_LOADER_EXPORT___;
