import React from "react";
import { AccordionCollection, Icon } from "halifax";
import { ImportantInfoBody } from "./ImportantInfoBody";
import type { Coverage } from "../../../../features/change-for-any-reason/types";

import styles from "./styles.module.scss";

export const ImportantInfoAccordion: React.FC<Coverage> = ({ coverage }) => {
  return (
    <AccordionCollection
      accordionContents={[
        {
          title: "Important information",
          body: <ImportantInfoBody coverage={coverage} />,
        },
      ]}
      className={styles["important-info-accordion-root"]}
      expandIcon={<Icon name="plus" />}
      collapseIcon={<Icon name="minus" />}
    />
  );
};
