import React from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useDeviceTypes } from "halifax";

import { HeaderDateField } from "./HeaderDateField";
import styles from "./styles.module.scss";

interface DatePickerHeadersProps {
  showReturn: boolean;
  selectedDates: [Date | null, Date | null];
}

export const DatePickerHeader: React.FC<DatePickerHeadersProps> = ({
  showReturn,
  selectedDates,
}) => {
  const { matchesMobile } = useDeviceTypes();
  return (
    <Box
      className={clsx(styles["date-picker-header-container"], {
        [styles["mobile"]]: matchesMobile,
      })}
    >
      <Typography variant="body1">{`Select the ${
        showReturn ? "dates" : "date"
      } for your
          new flight`}</Typography>
      {matchesMobile && (
        <Box className={styles["date-picker-date-fields-wrapper"]}>
          <HeaderDateField date={selectedDates[0]} label="Outbound" />
          {showReturn && (
            <HeaderDateField date={selectedDates[1]} label="Return" />
          )}
        </Box>
      )}
    </Box>
  );
};
