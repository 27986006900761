// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".M2nCpNvwBo1Ny3U9j\\+25\\+g\\=\\={display:flex;align-items:center;padding:30px 0 48px;width:100%}.M2nCpNvwBo1Ny3U9j\\+25\\+g\\=\\=.wPlSISFm8PwOU3dthEtJgA\\=\\={display:none}.Tm5GN6xNWTHWjm\\+4Bju7gQ\\=\\={color:var(--blue-9, #013d58);width:8px;margin-right:8px}.XgSLC-QmcovcqWnhDo98dw\\=\\={color:var(--blue-9, #013d58);font-weight:600;font-size:14px;line-height:18px}", "",{"version":3,"sources":["webpack://./../../cap1-modules/trips-module/src/pages/ChangeForAnyReason/components/GoBackButton/styles.module.scss"],"names":[],"mappings":"AAAA,8BACE,YAAA,CACA,kBAAA,CACA,mBAAA,CACA,UAAA,CACA,yDACE,YAAA,CAGJ,6BACE,4BAAA,CACA,SAAA,CACA,gBAAA,CAGF,4BACE,4BAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".go-back-button-root {\n  display: flex;\n  align-items: center;\n  padding: 30px 0 48px;\n  width: 100%;\n  &.mobile {\n    display: none;\n  }\n}\n.go-back-icon {\n  color: var(--blue-9, #013d58);\n  width: 8px;\n  margin-right: 8px;\n}\n\n.button-copy {\n  color: var(--blue-9, #013d58);\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"go-back-button-root": "M2nCpNvwBo1Ny3U9j+25+g==",
	"mobile": "wPlSISFm8PwOU3dthEtJgA==",
	"go-back-icon": "Tm5GN6xNWTHWjm+4Bju7gQ==",
	"button-copy": "XgSLC-QmcovcqWnhDo98dw=="
};
export default ___CSS_LOADER_EXPORT___;
