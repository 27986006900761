import React from "react";
import { Box, Typography } from "@material-ui/core";
import type { Airline } from "../../../../features/change-for-any-reason/types";
import { AirlineDisplay } from "./AirlineDisplay";

import styles from "./styles.module.scss";

type DesktopFlightItineraryTileProps = {
  formattedDate: string;
  originName: string;
  departureTime: string;
  destinationName: string;
  arrivalTime: string;
  flightDuration: string;
  flightStops: string;
  airlines: Airline[];
};

export const DesktopFlightItineraryTile: React.FC<
  DesktopFlightItineraryTileProps
> = ({
  formattedDate,
  originName,
  departureTime,
  destinationName,
  arrivalTime,
  flightStops,
  flightDuration,
  airlines,
}) => {
  return (
    <Box className={styles["desktop-flight-itinerary-tile-root"]}>
      <Typography
        variant="body1"
        className={styles["desktop-flight-itinerary-tile-header"]}
      >
        {`${originName} to `}
        <strong>{destinationName}</strong>
      </Typography>
      <Box className={styles["desktop-flight-itinerary-tile-details"]}>
        <Typography variant="body2">{formattedDate}</Typography>
        <Typography variant="body2">{`${departureTime} \u2013 ${arrivalTime}`}</Typography>
        <Typography variant="body2">{`${flightDuration}, ${flightStops}`}</Typography>
        <Box>
          {airlines.map(({ code, name }) => (
            <AirlineDisplay key={code} name={name} code={code} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
