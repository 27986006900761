import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { PATH_CHANGE_FOR_ANY_REASON } from "../../../utils/paths";

export const useNavigateToChangeForAnyReason = () => {
  const history = useHistory();
  return useCallback(() => {
    history.push(PATH_CHANGE_FOR_ANY_REASON);
  }, [history]);
};
