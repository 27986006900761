import React from "react";
import { getTotalPriceText, twoDecimalFormatter } from "halifax";
import type { Coverage } from "../../../../features/change-for-any-reason/types";
import { Box, Typography } from "@material-ui/core";

import styles from "./styles.module.scss";

export const ImportantInfoBody: React.FC<Coverage> = ({ coverage }) => {
  const totalCoverage = getTotalPriceText({
    price: coverage,
    priceFormatter: twoDecimalFormatter,
  });

  return (
    <Box>
      <ol className={styles["important-info-list"]}>
        <Typography component="li" variant="body2">
          We'll apply your original base fare and taxes ({totalCoverage})
          towards your new flight. You'll only pay the difference, if any.
        </Typography>
        <Typography component="li" variant="body2">
          Your new flight must be within 1 year of your original scheduled
          departure.
        </Typography>
        <Typography component="li" variant="body2">
          Change your flight for any reason may only be redeemed once. Any
          additional changes are subject to the airlines rules.
        </Typography>
        <Typography component="li" variant="body2">
          Baggage and/or seat fees, and other trip extras added to your original
          booking, are non transferable.
        </Typography>
        <Typography component="li" variant="body2">
          You will be changing the itinerary for all passengers on your original
          booking. If you would like to make changes for just one passenger,
          please give us a call at{" "}
          <a
            href="tel:844-422-6972"
            className={styles["important-info-phone-link"]}
          >
            844-422-6972
          </a>
          .
        </Typography>
      </ol>
      <Typography variant="body2">
        Flight availability is not guaranteed. All available options will be
        presented when booking your new flight.
      </Typography>
    </Box>
  );
};
