import React, { useState } from "react";
import { IconName } from "halifax";
import { EditTrigger } from "../EditTrigger";
import { RoutePickerModal } from "./RoutePickerModal";

interface ChangeRouteProps {
  originName: string;
  originCode: string;
  destinationName: string;
  destinationCode: string;
  handleOnSave: () => void;
}

export const ChangeRoute: React.FC<ChangeRouteProps> = ({
  originName,
  originCode,
  destinationName,
  destinationCode,
}) => {
  // @FLEX-TODO: We need to add the logic here to handle updating the route
  const [isOpen, setIsOpen] = useState(false);
  const selectedRoute = `${originName} (${originCode}), ${destinationName} (${destinationCode})`;
  return (
    <>
      <EditTrigger
        icon={IconName.B2BMapPin}
        title="Edit origin/destination"
        value={selectedRoute}
        onClick={() => setIsOpen(true)}
        isUpdated={false}
      />
      <RoutePickerModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
