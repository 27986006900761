import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { ActionButton, useDeviceTypes } from "halifax";

import styles from "./styles.module.scss";
import { ModalWrapper } from "../../ModalWrapper";
import { RoutePickerHeader } from "../RoutePickerHeader";
import { Box } from "@material-ui/core";
import { OriginDestinationSearch } from "../OriginDestinationSearch";
import { ITripTerminus } from "redmond";

interface RoutePickerModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const RoutePickerModal: React.FC<RoutePickerModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const [origin, setOrigin] = useState<ITripTerminus | null>(null);
  const [destination, setDestination] = useState<ITripTerminus | null>(null);

  const { matchesMobile } = useDeviceTypes();

  const handleClose = useCallback(
    (shouldSave = false) => {
      setIsOpen(false);
      if (shouldSave) {
        // save route
      }
    },
    [setIsOpen]
  );

  return (
    <ModalWrapper
      open={isOpen}
      onClose={() => handleClose()}
      modalClassName={clsx(styles["route-picker-popup-root"], {
        mobile: matchesMobile,
      })}
      headerElement={<RoutePickerHeader />}
    >
      <Box className={styles["route-picker-container"]}>
        <OriginDestinationSearch
          origin={origin}
          setOrigin={setOrigin}
          destination={destination}
          setDestination={setDestination}
        />
      </Box>
      <ActionButton
        disabled={false}
        data-test-id="save-route-button"
        className={clsx(styles["save-route-button"], {
          [styles["mobile"]]: matchesMobile,
        })}
        message="Save"
        onClick={() => handleClose(true)}
      />
    </ModalWrapper>
  );
};
