import {
  CANCELLATIONS,
  EXCHANGES,
  Restriction,
  ShopSymbol,
  TripAncillaryContracts,
} from "redmond";
import {
  CFAR_TITLE,
  CHFAR_TITLE,
} from "../components/ItineraryList/components/AddOnsSummary/constants";

const ANCILLARY_PENALTY_MAP = {
  cfar: CANCELLATIONS,
  chfar: EXCHANGES,
} as const;

type RestrictionAncillary = keyof typeof ANCILLARY_PENALTY_MAP;
/**
 * This will return a list of all active ancillaries for a given flight that may impact the restrictions shown for a flight.
 * Right now this is limited to CFAR and ChFAR.
 */
const getActiveRestrictionAncillaries = (
  ancillaries: TripAncillaryContracts
) => {
  return Object.keys(ancillaries)
    .filter(
      (ancillaryKey): ancillaryKey is RestrictionAncillary =>
        ancillaryKey === "cfar" || ancillaryKey === "chfar"
    )
    .filter((ancillaryKey) => ancillaries[ancillaryKey]!.isExercisable);
};

export const updateRestrictionWithAncillaries =
  (ancillaries: TripAncillaryContracts) =>
  (restriction: Restriction): Restriction => {
    const activeRestrictionAncillaries =
      getActiveRestrictionAncillaries(ancillaries);
    const restrictionToUpdate = activeRestrictionAncillaries.find(
      (a) => ANCILLARY_PENALTY_MAP[a] === restriction.name
    );

    if (restrictionToUpdate === "cfar") {
      return {
        ...restriction,
        description: CFAR_TITLE,
        symbol: ShopSymbol.INCLUDED,
      };
    }
    if (restrictionToUpdate === "chfar") {
      return {
        ...restriction,
        description: CHFAR_TITLE,
        symbol: ShopSymbol.INCLUDED,
      };
    }
    return restriction;
  };
