import React, { useState } from "react";
import { IconName, PickerType } from "halifax";
import { EditTrigger } from "../EditTrigger";
import type { SearchFlightDates } from "../../../../features/change-for-any-reason/types";
import { DatePickerModal } from "./DatePickerModal";
import { formatDate } from "../../../../features/change-for-any-reason/utils";

interface ChangeDatesProps {
  flightDates: SearchFlightDates;
  updatedFlightDates?: SearchFlightDates;
  handleOnSave: (startDate: Date, endDate: Date | null) => void;
}

export const ChangeDates: React.FC<ChangeDatesProps> = ({
  handleOnSave,
  flightDates,
  updatedFlightDates,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectedDates = updatedFlightDates ?? flightDates;
  const displayDates = selectedDates.returnDate
    ? `${formatDate(selectedDates.departureDate)} \u2013 ${formatDate(
        selectedDates.returnDate
      )}`
    : formatDate(selectedDates.departureDate);

  return (
    <>
      <EditTrigger
        icon={IconName.Calendar}
        title={
          selectedDates.returnDate ? "Edit travel dates" : "Edit travel date"
        }
        isUpdated={updatedFlightDates != null}
        value={displayDates}
        onClick={() => setIsOpen(true)}
      />
      <DatePickerModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        pickerType={
          selectedDates.returnDate != null ? PickerType.RANGE : PickerType.DAY
        }
        savedStartDate={
          updatedFlightDates?.departureDate
            ? new Date(updatedFlightDates.departureDate)
            : null
        }
        savedEndDate={
          updatedFlightDates?.returnDate
            ? new Date(updatedFlightDates.returnDate)
            : null
        }
        saveUpdateFlightDates={handleOnSave}
      />
    </>
  );
};
