import React from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useDeviceTypes } from "halifax";

import styles from "./styles.module.scss";

export const RoutePickerHeader: React.FC = () => {
  const { matchesMobile } = useDeviceTypes();
  return (
    <Box
      className={clsx(styles["route-picker-header-container"], {
        [styles["mobile"]]: matchesMobile,
      })}
    >
      <Typography className={styles["route-picker-header"]} variant="body1">
        Select the origin and destination
      </Typography>
      <Typography className={styles["route-picker-text"]} variant="body1">
        Enter the origin and destination for the flights you'd like to search
        for.
      </Typography>
    </Box>
  );
};
