import React from "react";
import {
  formatShortDate,
  getLocalTime,
  getTimeDifference,
  pluralize,
  useDeviceTypes,
} from "halifax";
import type { DateTime } from "redmond";
import { MobileFlightItineraryTile } from "./MobileFlightItineraryTile";
import { DesktopFlightItineraryTile } from "./DesktopFlightItineraryTile";
import type { Airline } from "../../../../features/change-for-any-reason/types";

interface FlightItineraryTileProps {
  slice: {
    originName: string;
    originCode: string;
    departureDate: DateTime;
    destinationName: string;
    destinationCode: string;
    arrivalDate: DateTime;
    stops: number;
    airlines: Airline[];
  };
}

export const FlightItineraryTile: React.FC<FlightItineraryTileProps> = ({
  slice,
}) => {
  const {
    originName,
    originCode,
    destinationName,
    destinationCode,
    airlines,
    stops: numberOfStops,
    departureDate,
    arrivalDate,
  } = slice;
  const { matchesMobile } = useDeviceTypes();

  const formattedDate = formatShortDate(departureDate);
  const departureTime = getLocalTime(departureDate);
  const arrivalTime = getLocalTime(arrivalDate);

  const stops = `${numberOfStops} ${pluralize(numberOfStops, "stop", "stops")}`;

  const flightStops = numberOfStops === 0 ? "Nonstop" : stops;

  const flightDuration = getTimeDifference(arrivalDate, departureDate);

  if (matchesMobile) {
    return (
      <MobileFlightItineraryTile
        formattedDate={formattedDate}
        originName={originName}
        originCode={originCode}
        departureTime={departureTime}
        destinationName={destinationName}
        destinationCode={destinationCode}
        arrivalTime={arrivalTime}
        flightDuration={flightDuration}
        flightStops={flightStops}
        airlines={airlines}
      />
    );
  }
  return (
    <DesktopFlightItineraryTile
      formattedDate={formattedDate}
      originName={originName}
      departureTime={departureTime}
      destinationName={destinationName}
      arrivalTime={arrivalTime}
      flightDuration={flightDuration}
      flightStops={flightStops}
      airlines={airlines}
    />
  );
};
