import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import { Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { removeTimezone, useDeviceTypes } from "halifax";

import { GoBackButton } from "./components/GoBackButton";
import { ChangeForAnyReasonHeader } from "./components/ChangeForAnyReasonHeader";
import { FlightItineraryTile } from "./components/FlightItineraryTile";
import { ImportantInfoAccordion } from "./components/ImportantInfoAccordion";
import { FlightSearchInfoPanel } from "./components/FlightSearchInfoPanel";
import { SearchButton } from "./components/SearchButton";
import { ChangeRoute } from "./components/ChangeRoute";
import { ChangeDates } from "./components/ChangeDates";
import { PATH_HOME } from "../../utils/paths";
import { useChFARPolicy } from "../../features/change-for-any-reason/hooks/useGetChFAR";
import type { SearchFlightDates } from "../../features/change-for-any-reason/types";

import styles from "./styles.module.scss";

const normalizeFlightDates = (date: string) =>
  dayjs(removeTimezone(date)).toDate();

export const ChangeForAnyReason = () => {
  const [updatedFlightDates, setUpdatedFlightDates] =
    React.useState<SearchFlightDates>();

  const { matchesMobile } = useDeviceTypes();
  const history = useHistory();

  const handleGoBack = useCallback(() => {
    history.push(PATH_HOME);
  }, [history]);

  const handleOnSaveFlightDates = (startDate: Date, endDate: Date | null) => {
    setUpdatedFlightDates({
      departureDate: startDate,
      ...(endDate && { returnDate: endDate }),
    });
  };

  const { slices, coverage, flightRoute, flightDates } = useChFARPolicy();

  const normalizedFlightDates = useMemo(
    () => ({
      departureDate: normalizeFlightDates(flightDates.departureDate),
      ...(flightDates.returnDate && {
        returnDate: normalizeFlightDates(flightDates.returnDate),
      }),
    }),
    [flightDates]
  );

  const searchDates = updatedFlightDates ?? normalizedFlightDates;

  const handleOnSearch = useCallback(() => {
    const dateQueryParams = Object.keys(searchDates).reduce((params, key) => {
      params[key] = dayjs(searchDates[key]).format("YYYY-MM-DD");
      return params;
    }, {} as Record<string, string>);

    const query = new URLSearchParams(dateQueryParams);

    console.log(
      "Search button clicked. Flight search params:",
      query.toString()
    );
  }, [searchDates]);

  return (
    <Box
      data-test-id="change-for-any-reason-page-root"
      className={clsx(styles["change-for-any-reason-root"], {
        [styles["mobile"]]: matchesMobile,
      })}
    >
      <ChangeForAnyReasonHeader handleGoBack={handleGoBack} />
      <Box className={styles["change-for-any-reason-body"]}>
        <GoBackButton handleGoBack={handleGoBack} />
        <Box className={styles["change-for-any-reason-body-content"]}>
          <Typography variant="h2" className={styles["title-copy"]}>
            Change your flight
          </Typography>
          <Typography variant="body2">
            Your flight includes{" "}
            <strong>change your flight for any reason</strong>. You can pick a
            new flight to change dates, times, origin/destination, or even
            airline up to 3 hours before departure.
          </Typography>
          <Box
            className={clsx(
              styles["flight-itinerary-tiles"],
              styles["section"]
            )}
          >
            {slices.map((slice) => (
              <FlightItineraryTile slice={slice} />
            ))}
          </Box>
          <ImportantInfoAccordion coverage={coverage} />
          <Box className={styles["section"]}>
            <Typography
              variant="h3"
              className={styles["search-new-flight-title"]}
            >
              Enter your search criteria for a new flight
            </Typography>
            <ChangeDates
              flightDates={normalizedFlightDates}
              updatedFlightDates={updatedFlightDates}
              handleOnSave={handleOnSaveFlightDates}
            />
            <ChangeRoute
              originName={flightRoute.originName}
              originCode={flightRoute.originCode}
              destinationName={flightRoute.destinationName}
              destinationCode={flightRoute.destinationCode}
              handleOnSave={() => {}}
            />
            <FlightSearchInfoPanel />
            <SearchButton handleOnClick={handleOnSearch} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
