// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VabfhWfl6XbYMfrecfk5\\+g\\=\\={margin-top:20px;padding:8px 20px;background-color:var(--takeover-background-blue, #e1f5ff);border-left:3px solid var(--blue-9, #013d58);display:flex}.VabfhWfl6XbYMfrecfk5\\+g\\=\\= .YTd-ypr4-BShPHBdjGacnA\\=\\={width:15px;height:15px;flex-shrink:0;margin-top:3px;margin-right:8px}.VabfhWfl6XbYMfrecfk5\\+g\\=\\= .X1CbAURHTvr8v1sXSdsJRA\\=\\={color:var(--blue-9, #013d58)}", "",{"version":3,"sources":["webpack://./../../cap1-modules/trips-module/src/pages/ChangeForAnyReason/components/FlightSearchInfoPanel/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,eAAA,CACA,gBAAA,CACA,yDAAA,CACA,4CAAA,CACA,YAAA,CACA,yDACE,UAAA,CACA,WAAA,CACA,aAAA,CACA,cAAA,CACA,gBAAA,CAEF,yDACE,4BAAA","sourcesContent":[".flight-search-info-panel-root {\n  margin-top: 20px;\n  padding: 8px 20px;\n  background-color: var(--takeover-background-blue, #e1f5ff);\n  border-left: 3px solid var(--blue-9, #013d58);\n  display: flex;\n  .flight-search-info-panel-icon {\n    width: 15px;\n    height: 15px;\n    flex-shrink: 0;\n    margin-top: 3px;\n    margin-right: 8px;\n  }\n  .flight-search-info-panel-body {\n    color: var(--blue-9, #013d58);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flight-search-info-panel-root": "VabfhWfl6XbYMfrecfk5+g==",
	"flight-search-info-panel-icon": "YTd-ypr4-BShPHBdjGacnA==",
	"flight-search-info-panel-body": "X1CbAURHTvr8v1sXSdsJRA=="
};
export default ___CSS_LOADER_EXPORT___;
