import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Icon, IconName } from "halifax";

import styles from "./styles.module.scss";

export const FlightSearchInfoPanel = () => {
  return (
    <Box className={styles["flight-search-info-panel-root"]}>
      <Icon
        name={IconName.InfoCircle}
        className={styles["flight-search-info-panel-icon"]}
      />
      <Typography
        variant="body1"
        className={styles["flight-search-info-panel-body"]}
      >
        Confirm the dates and airports above and proceed with your search. You
        can change to a different airline or departure time when selecting your
        new flight.
      </Typography>
    </Box>
  );
};
