import React from "react";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import { AirlineIcon, useDeviceTypes } from "halifax";
import type { Airline } from "../../../../features/change-for-any-reason/types";
import styles from "./styles.module.scss";

export const AirlineDisplay: React.FC<Airline> = ({ name, code }) => {
  const { matchesMobile } = useDeviceTypes();
  return (
    <Box
      className={clsx(styles["airline-display-root"], {
        [styles["mobile"]]: matchesMobile,
      })}
    >
      <AirlineIcon
        className={styles["airline-display-icon"]}
        airlineCode={code}
      />
      <Typography variant="body2">{name}</Typography>
    </Box>
  );
};
