import React from "react";
import { Box, Typography } from "@material-ui/core";
import { ButtonWrap, Icon, IconName, useDeviceTypes } from "halifax";

import "./styles.scss";
import * as textConstants from "./constants";
import { AddOn, AddOnEnum } from "./types";
import clsx from "clsx";
import { AddOnsSummaryConnectorProps } from "./container";

interface IAddOnsSummary extends AddOnsSummaryConnectorProps {
  addOns: AddOn[];
  className?: string;
  label?: string;
}

export const AddOnsSummary = (props: IAddOnsSummary) => {
  const { matchesMobile } = useDeviceTypes();
  const { addOns, className, label = textConstants.ADD_ONS_TITLE } = props;

  const renderAddOnItem = ({
    index,
    onClick,
    isEnabled,
    buttonCopy,
    buttonIconName,
    buttonComponent,
    buttonAriaLabel,
    buttonClassName,
  }: {
    index: number;
    onClick: () => void;
    isEnabled: boolean;
    buttonCopy: string;
    buttonIconName: IconName;
    buttonComponent?: JSX.Element;
    buttonAriaLabel?: string;
    buttonClassName?: string;
  }) => {
    return (
      <Box
        key={`add-on-${index}`}
        className={clsx("add-on-item-root", buttonClassName)}
      >
        <ButtonWrap
          className={clsx("add-on-item-button", {
            disabled: !isEnabled,
          })}
          aria-label={buttonAriaLabel}
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            if (isEnabled) onClick();
          }}
        >
          <Icon className="add-on-item-icon" name={buttonIconName} />
          <Typography className="add-on-item-button-text">
            {buttonCopy}
          </Typography>
        </ButtonWrap>
        {buttonComponent}
      </Box>
    );
  };

  const getAddOnsContent = () => {
    return addOns.map((addOn, index) => {
      switch (addOn.type) {
        case AddOnEnum.DisruptionProtection:
          return renderAddOnItem({
            index,
            onClick: addOn.onClick,
            isEnabled: addOn.isEnabled,
            buttonCopy: textConstants.DISRUPTION_PROTECTION_TITLE,
            buttonIconName: IconName.DisruptionProtectionBlue,
            buttonAriaLabel:
              textConstants.DISRUPTION_PROTECTION_BUTTON_ARIA_TEXT,
            buttonClassName: "disruption-protection",
          });
        case AddOnEnum.Cfar:
          return renderAddOnItem({
            index,
            onClick: addOn.onClick,
            isEnabled: addOn.isEnabled,
            buttonCopy: textConstants.CFAR_TITLE,
            buttonIconName: IconName.CheckShieldBlue,
            buttonComponent: (
              <Box className="expiry-date">
                <Typography className="expiry-copy">
                  {textConstants.EXPIRY_DATE_COPY(
                    addOn.expiryDate,
                    addOn.expiredTz
                  )}
                </Typography>
              </Box>
            ),
            buttonAriaLabel: textConstants.CFAR_BUTTON_ARIA_TEXT,
            buttonClassName: "cfar",
          });
        case AddOnEnum.Chfar:
          return renderAddOnItem({
            index,
            onClick: addOn.onClick,
            isEnabled: addOn.isEnabled,
            buttonCopy: textConstants.CHFAR_TITLE,
            buttonIconName: IconName.Reload,
            buttonComponent: (
              <Box className="expiry-date">
                <Typography className="expiry-copy">
                  {textConstants.EXPIRY_DATE_COPY(
                    addOn.expiryDate,
                    addOn.expiredTz
                  )}
                </Typography>
              </Box>
            ),
            buttonAriaLabel: textConstants.CHFAR_BUTTON_ARIA_TEXT,
            buttonClassName: "chfar",
          });
        default:
          return null;
      }
    });
  };

  return (
    <Box
      className={clsx("add-ons-summary-container", className, {
        mobile: matchesMobile,
      })}
    >
      <Typography variant="body2" className="add-ons-text">
        {label}
      </Typography>
      <Box className="add-ons-content-section">{getAddOnsContent()}</Box>
    </Box>
  );
};
