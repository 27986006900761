// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nlSVIpdEfM4CaVVTW-rPzA\\=\\={padding:40px 40px 22px}.nlSVIpdEfM4CaVVTW-rPzA\\=\\= .U43WxOqPHAqecgKax9d0wQ\\=\\={font-size:18px}.nlSVIpdEfM4CaVVTW-rPzA\\=\\=._2q-EYayojJdI6kopSzgQ7Q\\=\\={margin-top:50px;padding:20px 16px 0px;border-top:1px solid var(--grey-11)}.nlSVIpdEfM4CaVVTW-rPzA\\=\\=._2q-EYayojJdI6kopSzgQ7Q\\=\\= .U43WxOqPHAqecgKax9d0wQ\\=\\={font-size:20px}", "",{"version":3,"sources":["webpack://./../../cap1-modules/trips-module/src/pages/ChangeForAnyReason/components/ChangeRoute/RoutePickerHeader/styles.module.scss"],"names":[],"mappings":"AAAA,4BACE,sBAAA,CACA,wDACE,cAAA,CAEF,wDACE,eAAA,CACA,qBAAA,CACA,mCAAA,CACA,oFACE,cAAA","sourcesContent":[".route-picker-header-container {\n  padding: 40px 40px 22px;\n  .route-picker-header {\n    font-size: 18px;\n  }\n  &.mobile {\n    margin-top: 50px;\n    padding: 20px 16px 0px;\n    border-top: 1px solid var(--grey-11);\n    .route-picker-header {\n      font-size: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"route-picker-header-container": "nlSVIpdEfM4CaVVTW-rPzA==",
	"route-picker-header": "U43WxOqPHAqecgKax9d0wQ==",
	"mobile": "_2q-EYayojJdI6kopSzgQ7Q=="
};
export default ___CSS_LOADER_EXPORT___;
