import React from "react";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import { ButtonWrap, useDeviceTypes } from "halifax";
import { BACK_TO_MY_TRIPS } from "../../../../features/change-for-any-reason/constants";
import styles from "./styles.module.scss";

type GoBackButtonProps = {
  handleGoBack: () => void;
};

export const GoBackButton: React.FC<GoBackButtonProps> = ({ handleGoBack }) => {
  const { matchesMobile } = useDeviceTypes();

  if (matchesMobile) {
    return null;
  }

  return (
    <ButtonWrap
      className={styles["go-back-button-root"]}
      onClick={handleGoBack}
    >
      <FontAwesomeIcon
        className={styles["go-back-icon"]}
        icon={faChevronLeft}
      />
      <Typography className={styles["button-copy"]} variant="subtitle1">
        {BACK_TO_MY_TRIPS}
      </Typography>
    </ButtonWrap>
  );
};
