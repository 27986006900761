import React from "react";
import { Box, Typography } from "@material-ui/core";
import { ArrowLink, Icon, Tag, type IconName } from "halifax";

import styles from "./styles.module.scss";

interface EditTriggerProps {
  icon: IconName;
  title: string;
  value: string;
  isUpdated: boolean;
  onClick: () => void;
}

export const EditTrigger: React.FC<EditTriggerProps> = ({
  icon,
  title,
  value,
  isUpdated,
  onClick,
}) => {
  return (
    <Box className={styles["edit-trigger-root"]}>
      <Icon name={icon} className={styles["edit-trigger-icon"]} />
      <Box className={styles["edit-trigger-content"]}>
        <ArrowLink onClick={onClick} content={title} />
        <Box className={styles["edit-trigger-value"]}>
          <Typography variant="body2">{value}</Typography>
          {isUpdated && (
            <Tag
              label="Updated"
              className={styles["edit-trigger-value-updated-tag"]}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
