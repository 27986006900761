import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Icon, IconName } from "halifax";

import { formatDate } from "../../../../../features/change-for-any-reason/utils";
import styles from "./styles.module.scss";

interface HeaderDateFieldProps {
  date: Date | null;
  label: string;
}

export const HeaderDateField: React.FC<HeaderDateFieldProps> = ({
  date,
  label,
}) => {
  return (
    <Box className={styles["date-field-wrapper"]}>
      <Icon name={IconName.Calendar} />
      <Box className={styles["selected-date-input"]}>
        <Typography className={styles["eyebrow"]}>
          {date ? label : ""}
        </Typography>
        <Typography className={styles["selected-date-value"]}>
          {date ? formatDate(date) : label}
        </Typography>
      </Box>
    </Box>
  );
};
