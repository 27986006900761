import React from "react";
import { Box } from "@material-ui/core";
import { Icon, IconName } from "halifax";
import { ITripTerminus, RecentFlightSearch } from "redmond";
import {
  OriginAutocomplete,
  DestinationAutocomplete,
} from "../TerminusAutocomplete";
import "./styles.scss";
import clsx from "clsx";

interface IOriginDestinationSearchProps {
  origin: ITripTerminus | null;
  setOrigin: (origin: ITripTerminus | null) => void;
  destination: ITripTerminus | null;
  setDestination: (destination: ITripTerminus | null) => void;
  hasMissingSearchInfoError?: boolean;
  withSwap?: boolean;
  disabled?: boolean;
  recentSearches?: RecentFlightSearch[];
  onRecentSearchClick?: (search: RecentFlightSearch) => void;
}

export const OriginDestinationSearch = ({
  origin,
  setOrigin,
  destination,
  setDestination,
  disabled,
}: IOriginDestinationSearchProps) => {
  return (
    <Box className={clsx("multicity-location-pickers")}>
      <OriginAutocomplete
        className="origin-auto-complete b2b"
        customIcon={
          <Icon name={IconName.B2BMapPin} ariaLabel="" aria-hidden={true} />
        }
        label="Where from?"
        value={origin}
        setValue={setOrigin}
        disabled={disabled}
        getOptionSelected={(
          option: ITripTerminus | null,
          value: ITripTerminus | null
        ) => {
          return (
            !!value &&
            !!option &&
            value.id.code.code === option.id.code.code &&
            value.label === option.label
          );
        }}
      />
      <DestinationAutocomplete
        className="destination-auto-complete b2b"
        customIcon={
          <Icon name={IconName.B2BMapPin} ariaLabel="" aria-hidden={true} />
        }
        label="Where to?"
        value={destination}
        setValue={setDestination}
        disabled={disabled}
        getOptionSelected={(
          option: ITripTerminus | null,
          value: ITripTerminus | null
        ) => {
          return (
            !!value &&
            !!option &&
            value.id.code.code === option.id.code.code &&
            value.label === option.label
          );
        }}
      />
    </Box>
  );
};
