import type { ChFARPolicyDetails } from "../types";

export const useChFARPolicy = (): ChFARPolicyDetails => {
  // @TODO: Implement this when the API is ready
  return {
    slices: [
      {
        id: "1",
        originName: "Toronto",
        originCode: "YYZ",
        departureDate: "2025-02-03T18:29-05:00",
        destinationName: "Vancouver",
        destinationCode: "YVR",
        arrivalDate: "2025-02-03T22:09-08:00",
        stops: 1,
        airlines: [
          {
            name: "Air Canada",
            code: "AC",
          },
        ],
      },
      {
        id: "2",
        originName: "Vancouver",
        originCode: "YVR",
        departureDate: "2025-02-07T14:29-08:00",
        destinationName: "Toronto",
        destinationCode: "YYZ",
        arrivalDate: "2025-02-07T20:09-05:00",
        stops: 1,
        airlines: [
          {
            name: "Air Canada",
            code: "AC",
          },
        ],
      },
    ],
    coverage: { value: 300, currencyCode: "USD", currencySymbol: "$" },
    flightRoute: {
      originName: "Toronto",
      originCode: "YYZ",
      destinationName: "Vancouver",
      destinationCode: "YVR",
    },
    flightDates: {
      departureDate: "2025-02-03T18:29-05:00",
      returnDate: "2025-02-07T14:29-08:00",
    },
  };
};
